@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500');

*, ::before, ::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

:root {

  /*colors */
  --body-bg-color: #FFFFFF;
  --sidebar-bg-color: #f4f4f4;
  --text-color: #333333;
  --title-color: #111111;
  --date-color: #1f94c2;

  --sidebar-bg-color: #b8f4ff;

  /* --dark-body-bg-color */
  --dark-body-bg-color: #242424;
  --dark-sidebar-bg-color: #171717;
  --dark-text-color: #d8d8d8;
  --dark-title-color: #ffffff;
  --dark-date-color: #e6ef71;

  --icon-color:var(--text-color);
  --circle-color: #AAA;
  --circle-color-plain: #555;
  --dark-icon-color:var(--dark-text-color);
  --dark-circle-color: rgb(108, 108, 108);
  --dark-circle-color-plain: rgb(238, 238, 238);

  --circle-color: rgb(255, 255, 255);
  --circle-color-plain: rgb(50, 75, 189);

  

  /* fonts */
  --body-font: "Poppins", "Sans-serif";
  --h1-font-size: 1.7rem;
  --h2-font-size: 1.3rem;
  --h3-font-size: 1.1rem;
  --h4-font-size: 1rem;

  /* helpers */
  --spacing-1: .4rem;
  --spacing-2: .8rem;
  --spacing-3: 1.2rem;
  --spacing-4: 1.5rem;
  --spacing-5: 2rem;
}
body {
  font-family: var(--body-font) ;
  font-weight: 200;
  color: var(--text-color);
  background-color: var(--body-bg-color);
}

.dark {
  background-color: var(--dark-body-bg-color);
  color: var(--dark-text-color);
}

h1, h2, h3 {
  margin-bottom: var(--spacing-1);
  font-weight: 400;
  color: var(--title-color);
}

.dark h1, 
.dark h2, 
.dark h3 {
  color: var(--dark-title-color);
}
h1 {
  font-size: var(--h1-font-size);
  text-transform: uppercase;
}

h2 {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-4);
  font-size: var(--h2-font-size);
  letter-spacing: 4px;
  text-transform: uppercase;
  
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.dark button {
  color: var(--dark-text-color);
}

a{
  text-decoration: none;
  color: inherit;
}

p{
  margin-bottom: var(--spacing-2);
  font-weight: 300;
}

ul {
  padding-left: 1.3rem;
}

li {
  margin-bottom: var(--spacing-2) ;
}

/*helpers*/

.mb3{
  margin-bottom: var(--spacing-3);
}

.mb5{
  margin-bottom: var(--spacing-5);
}


/* grids*/

.grid_row {
  margin: var(--spacing-3)
}

@media screen and (min-width:768px) {
.grid_container{
  display: grid;
  grid-template-columns: 1fr 3fr;
  }
  .grid_row {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin: 0;
  }
}

/* layouts */
.sidebar{
  padding: 1.5rem;
  background-color: var(--sidebar-bg-color);
}

.dark .sidebar{
  background-color: var(--dark-sidebar-bg-color);
}

.actions {
  display: flex;
  justify-content: space-between;
}

/* icons */
.circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: var(--circle-color);
}

.circle-plain {
  background-color: var(--circle-color-plain);
}

.dark .circle {
  background-color: var(--dark-circle-color);
}

.dark .circle-plain {
  background-color: var(--dark-circle-color-plain);
}

.main{
  padding: 1.5rem;
}