.grid_item,
.grid_item p {
    margin-bottom: var(--spacing-1);
    font-weight: 400;
}

.grid_date {
    font-weight: 400;
}

.grid_title {
    margin-bottom: 0 ;
    font-weight: 500;
}

.grid_location {
    color:var(--date-color);
    font-weight: 500;
}

.barre-horizontale {
    margin: var(--spacing-1) 0;
    border-bottom: 1px solid var(--date-color);
    width: 70%;
}

.grid_mission {
    margin-bottom: var(--spacing-1) ;
}

