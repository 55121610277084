.skills {
    margin: var(--spacing-3) 0;
    font-weight: 300;
}

.skill {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.interests {
    align-items: center;
}

.interests svg {
    margin: var(--spacing-1);
}